import React, { useState, useEffect, useContext } from 'react';
import banner from '../assets/images/banner.jpg';
import { Link } from 'react-router-dom';
import { useIndexedDB } from 'react-indexed-db';
import { strings } from '../localization';

function Dashboard(){

    const { getAll } = useIndexedDB('catalog');
    const [customers, setCustomers] = useState([]);
    const [products, setProducts] = useState([]);
    const [orders, setOrders] = useState([]);

    useEffect(() => {
        getAll().then(dataFromDB => {

            setCustomers(dataFromDB.find(i => i.type == 'customers')?.data ?? [])
            setProducts(dataFromDB.find(i => i.type == 'products')?.data ?? [])
            setOrders(dataFromDB.find(i => i.type == 'orders')?.data ?? [])
        });
    }, []);

    sessionStorage.removeItem('catalog.current_selected_category');
    sessionStorage.removeItem('catalog.current_selected_page');
    sessionStorage.removeItem('catalog.current_selected_pagination');
    sessionStorage.removeItem('products.current_selected_page');
    sessionStorage.removeItem('products.current_selected_pagination');

    return (
        <div className="dashboard container-xxl">
            <img src={ banner } alt="dashboard banner" className="w-100 mb-5" />
          
            <div className="row flex-wrap">
                <div className='col-lg-4'>
                    <Link to='/customers'  className={ "btn" }>
                        <i className="col-lg-3 bg-primary fa fa-users p-3"></i>
                        <span className="p-3 text-center">{ strings.clients }</span>
                    </Link>
                </div>
                <div className='col-lg-4'>
                    <Link to={ products.length ? '/products' : '' }  className={ "btn "+ ( products.length ? '' : 'disabled') }>
                        <i className="col-lg-3 fa fa-gift"></i>
                        <span className="p-3 text-center">{ strings.articles }</span>
                    </Link>
                </div>
                <div className='col-lg-4'>
                    <Link to='/orders'   className={ "btn"}>
                        <i className="col-lg-3 fa fa-clipboard-check"></i>
                        <span className="p-3 text-center">{ strings.commandes }</span>
                    </Link>
                </div>
                <div className='col-lg-4'>
                    <Link to="/sync" className='btn'>
                        <i className="col-lg-3 fa fa-sync"></i>
                        <span className="p-3 text-center">{ strings.synchro }</span>
                    </Link>
                </div>
                <div className='col-lg-4'>
                <Link to={ products.length ? '/catalog' : '' }  className={ "btn "+ ( products.length ? '' : 'disabled') }>
                        <i className="col-lg-3 far fa-clone"></i>
                        <span className="p-3 text-center">{ strings.catalogue}</span>
                    </Link>
                </div>
                <div className='col-lg-4 d-none'>
                    <Link to="" className='btn disabled'>
                        <i className="col-lg-3 fa fa-sliders-h"></i>
                        <span className="p-3 text-center">{ strings.parametres }</span>
                    </Link>
                </div>

            </div>
            
        </div>
    )
}

export default Dashboard;